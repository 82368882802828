{
  "name": "ivm-meldpunt",
  "version": "1.2.11-hotfix1",
  "private": true,
  "engines": {
    "node": "22",
    "npm": "10"
  },
  "scripts": {
    "start": "node backend/dist/index.js",
    "ng": "ng",
    "batch": "cd backend && npm run batch",
    "typecheck": "tsc --noEmit --project tsconfig.app.json",
    "lint:check": "eslint src",
    "lint:fix": "eslint --fix src",
    "backend:watch": "cd backend && npm run dev:watch",
    "swagger": "cd backend && npm run swagger:external",
    "build": "cd backend && tsc && cd .. && ng build",
    "sdk": "cd backend && npm run sdk",
    "sdk:watch": "cd backend && npm run sdk:watch ",
    "angular:watch": "ng build --watch --configuration development",
    "dev:watch": "concurrently --names \"Angular,Backend,SDK\" -c \"bgGreen.bold,bgBlue.bold,bgMagenta.bold\" \"npm run angular:watch\" \"npm run backend:watch\" \"npm run sdk:watch\"",
    "postinstall": "cd backend && npm i",
    "release": "generate-release"
  },
  "config": {
    "generateRelease": {
      "files_to_version": [
        "azure-pipelines-docker-build.yml",
        "package.json",
        "package-lock.json",
        "backend/package.json",
        "backend/package-lock.json"
      ]
    }
  },
  "dependencies": {
    "@angular/animations": "^18.0.5",
    "@angular/common": "^18.0.5",
    "@angular/compiler": "^18.0.5",
    "@angular/core": "^18.0.5",
    "@angular/forms": "^18.0.5",
    "@angular/localize": "^18.0.5",
    "@angular/platform-browser": "^18.0.5",
    "@angular/platform-browser-dynamic": "^18.0.5",
    "@angular/router": "^18.0.5",
    "@microsoft/applicationinsights-web": "^2.8.18",
    "@ng-bootstrap/ng-bootstrap": "^17.0.0",
    "@popperjs/core": "2.11.8",
    "bootstrap": "5.3.2",
    "colors": "^1.4.0",
    "dayjs": "^1.11.10",
    "ngx-mask": "^17.1.8",
    "rxjs": "~7.8.0",
    "tslib": "^2.3.0",
    "zone.js": "^0.14.4"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^18.0.6",
    "@angular-eslint/builder": "^18.0.1",
    "@angular-eslint/eslint-plugin": "^18.0.1",
    "@angular-eslint/eslint-plugin-template": "^18.0.1",
    "@angular-eslint/schematics": "^18.0.1",
    "@angular-eslint/template-parser": "^18.0.1",
    "@angular/cli": "^18.0.6",
    "@angular/compiler-cli": "^18.0.5",
    "@openapitools/openapi-generator-cli": "^2.13.1",
    "@types/swagger-ui-express": "^4.1.7",
    "@typescript-eslint/eslint-plugin": "^5.62.0",
    "@typescript-eslint/parser": "^5.62.0",
    "concurrently": "^7.6.0",
    "dotenv": "^16.4.5",
    "eslint": "^8.57.0",
    "eslint-config-prettier": "^8.10.0",
    "eslint-plugin-imengine": "file:eslint",
    "eslint-plugin-prettier": "^4.2.1",
    "generate-release": "^1.1.1",
    "nodemon": "^2.0.22",
    "prettier": "^2.8.8",
    "tslint": "~6.1.0",
    "typescript": "~5.4.5",
    "webpack-bundle-analyzer": "^4.10.1"
  },
  "overrides": {
    "@babel/core": "7.26.10"
  }
}
